import { SelectChangeEvent } from '@mui/material';
import { Nutrient } from '../../../../types';

interface Props {
  setSelectedUnits: (value: React.SetStateAction<string[]>) => void;
  setNutrientValues: (value: React.SetStateAction<Nutrient[]>) => void;
}

export const useFormChangeActions = ({
  setSelectedUnits,
  setNutrientValues: setNutrientsValues,
}: Props) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedUnits(typeof value === 'string' ? value.split(',') : value);
  };

  const handleNutrientChange = (nutrient: Nutrient, value: string) => {
    setNutrientsValues((prev) => {
      const newArr = prev.map((n) => {
        if (n.id === nutrient.id) return { ...n, amount: Number(value) };
        return n;
      });

      return newArr;
    });
  };

  return {
    handleChange,
    handleNutrientChange,
  };
};
