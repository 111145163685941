import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  ComposerFooterButtons,
  KQNTextInputField,
  TextInputAndormnet,
  Title,
} from '../../../../components';
import { Ingredient, Nutrient, PageableUnit } from '../../../types';
import { IngredientUpdateSchemaType, updateIngredientSchema } from './schema';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type Props = {
  units: PageableUnit[];
  nutrients: Nutrient[];
  ingredient?: Ingredient;
  onSubmit: (data: any) => void;
  onNutrientChange: (nutrient: Nutrient, value: string) => void;
  onUnitChange: (event: SelectChangeEvent<string[]>) => void;
  selectedUnits: string[];
  onClose: () => void;
  isSubmitting?: boolean;
};

const UpsertIngredientForm: React.FC<Props> = ({
  units,
  onClose,
  onSubmit,
  nutrients,
  ingredient,
  onUnitChange,
  isSubmitting,
  selectedUnits,
  onNutrientChange,
}) => {
  const theme = useTheme();
  const { control, handleSubmit } = useForm<IngredientUpdateSchemaType>({
    resolver: zodResolver(updateIngredientSchema),
    defaultValues: {
      name: ingredient?.name || '',
      nameDe: ingredient?.nameDe || '',
    },
  });

  const handleUpdateNutrientValues = useCallback(
    (nutrient: Nutrient, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = Number(e.target.value);
      if (value < 0) return;

      onNutrientChange(nutrient, e.target.value);
    },
    [onNutrientChange],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={2}>
        <Stack direction={{ md: 'column' }} gap={1}>
          <Title title='Ingredient' />
          <Stack
            direction='row'
            justifyContent='space-between'
            gap={2}
            alignContent='center'
            alignItems='center'
          >
            <KQNTextInputField
              control={control}
              name='name'
              data-testid='ingredients-name-en-input'
              placeholder='Ingredient (EN)'
              InputProps={{
                startAdornment: <TextInputAndormnet text='EN' position='start' />,
              }}
            />
            <KQNTextInputField
              placeholder='Ingredient (DE)'
              name='nameDe'
              control={control}
              InputProps={{
                startAdornment: <TextInputAndormnet text='DE' position='start' />,
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={{ md: 'column' }} gap={1}>
          <Title title='Units' />
          <FormControl fullWidth>
            <Select
              placeholder='Units'
              defaultValue={[]}
              id='multiple-chip'
              data-testid='ingredients-unit-select'
              multiple
              value={selectedUnits}
              onChange={onUnitChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      sx={{ bgcolor: 'kqn.ultraLightCooper', color: 'kqn.cooper', height: 26 }}
                    />
                  ))}
                </Box>
              )}
              MenuProps={{
                ...MenuProps,
                sx: {
                  '&& .Mui-selected': {
                    backgroundColor: 'kqn.ultraLightCooper',
                  },
                },
              }}
              sx={{
                width: 'calc(50% - 8px)',
                height: '40px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'kqn.darkGray',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'kqn.darkGray',
                },
              }}
            >
              {units.map(({ id, name }) => (
                <MenuItem
                  key={id}
                  value={name}
                  style={getStyles(name, [], theme)}
                  data-testid='ingredients-unit-select-item'
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction='row' gap={4} alignItems='center' justifyContent='space-between' mb={3}>
          <Box flexDirection='column' display='flex' width='70%' gap={1}>
            <Typography variant='h6' sx={{ color: 'kqn.darkerGray' }}>
              Nutrition values per 100g, g
            </Typography>
            <Stack gap={4} justifyContent='space-between' alignItems='center' m={0} direction='row'>
              {nutrients
                .filter((n) => ['Fat', 'Carbs', 'Protein'].includes(n.name))
                .map((nutrient, idx) => (
                  <Box key={nutrient.id} data-testid='ingredients-nutrients-form'>
                    <TextField
                      sx={{ m: 0 }}
                      data-testid={`ingredients-nutrient-${idx}`}
                      value={nutrient.amount || ''}
                      onChange={(e) => handleUpdateNutrientValues(nutrient, e)}
                      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                      margin='normal'
                      InputProps={{
                        startAdornment: (
                          <TextInputAndormnet text={nutrient.name} position='start' />
                        ),
                        type: 'number',
                        inputMode: 'numeric',
                        inputProps: { style: { textAlign: 'center' } },
                      }}
                    />
                  </Box>
                ))}
            </Stack>
          </Box>
          <Box flexDirection='column' display='flex' width='30%' gap={1}>
            <Typography variant='h6' sx={{ color: 'kqn.darkerGray' }}>
              Energy per 100g, kcal
            </Typography>
            {nutrients
              .filter((n) => ['Energy'].includes(n.name))
              .map((nutrient, idx) => (
                <Box key={nutrient.id} data-testid='ingredients-nutrients-form'>
                  <TextField
                    sx={{ m: 0, width: '150px' }}
                    data-testid={`ingredients-nutrient-${idx}`}
                    value={nutrient.amount || ''}
                    onChange={(e) => handleUpdateNutrientValues(nutrient, e)}
                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                    margin='normal'
                    InputProps={{
                      startAdornment: <TextInputAndormnet text={nutrient.name} position='start' />,
                      type: 'number',
                      inputMode: 'numeric',
                      inputProps: { style: { textAlign: 'center' } },
                    }}
                  />
                </Box>
              ))}
          </Box>
        </Stack>
        <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
      </Stack>
    </form>
  );
};

export default UpsertIngredientForm;
