import { Stack } from '@mui/material';
import React from 'react';
import { Title } from '../../../../../../../../../components';
import { InputView } from '../../../../../../../../../shared/components';
import { Burner } from '../../../../../../../../types';
import { burnersSortingOrders } from '../../../../../../../helpers';
import { shorterBurnerName } from '../../preset/components';

type Props = {
  title: string;
  burners: Burner[];
  tooltipText?: string;
};

const BurnersView: React.FC<Props> = ({ title, burners, tooltipText }) => {
  if (!burners.length) return null;
  return (
    <Stack direction='column' gap={1}>
      <Title title={title} tooltipText={tooltipText} />
      <Stack direction='row' gap={2}>
        {burners
          .slice()
          ?.sort((a, b) => burnersSortingOrders[a.name] - burnersSortingOrders[b.name])
          .map((burner) => (
            <InputView
              key={burner.burnerId}
              value={String(burner.temperature)}
              adornment={shorterBurnerName(burner.name as keyof typeof shorterBurnerName)}
              inputProps={{
                color: 'kqn.disabled',
                maxWidth: '112px !important',
              }}
            />
          ))}
      </Stack>
    </Stack>
  );
};
export default BurnersView;
