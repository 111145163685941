import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { QueryKeys } from '../../constants';
import { recipeService } from '../../modules/recipes';
import { RecipeBaseModel } from '../../modules/types';
import { PaginatedResult } from '../../types';

type Props = {
  recipeIds: Array<string>;
  children: (args: {
    data: InfiniteData<PaginatedResult<RecipeBaseModel>>;
    ref: (node?: Element | null) => void;
    isLoading: boolean;
  }) => React.ReactNode;
};

const AssociatedRecipesHandler: React.FC<Props> = ({ recipeIds, children }) => {
  const { ref, inView } = useInView({ threshold: 0 });

  const { data, error, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: [QueryKeys.associatedRecipes, recipeIds],
    queryFn: async ({ pageParam }) => {
      const result = await recipeService.getRecipesByIds({ recipeIds, pageParams: pageParam || 1 });
      return result;
    },
    getNextPageParam: (metadata) => {
      if (metadata.meta.currentPage === metadata.meta.lastPage) {
        return undefined;
      }

      return metadata.meta.currentPage + 1;
    },
    enabled: recipeIds.length > 0,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (error) return <div>Error fetching recipes</div>;
  if (!data) return null;

  return <>{children({ data, ref, isLoading })}</>;
};

export default AssociatedRecipesHandler;
