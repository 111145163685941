import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal, KQNTextField, TextInputAndormnet, Title } from '../../../../components';
import { useDebounceValue } from '../../../../shared';
import { RecipeBaseModel } from '../../../types';
import { useDuplicateRecipe } from '../../mutations';
import { recipeService } from '../../services';

type Props = {
  recipe?: RecipeBaseModel;
  isOpen: boolean;
  onClose: (event?: any) => void;
};

const DuplicateRecipeModal: React.FC<Props> = ({ isOpen, onClose, recipe }) => {
  const navigate = useNavigate();
  const initialName = `${recipe?.name} - copy`;
  const initialNameDe = `${recipe?.nameDe} - kopie`;
  const { handleDuplicateRecipe, isLoading: isDuplicating } = useDuplicateRecipe();
  const [isSuccess, setIsSuccess] = useState(false);
  const [newRecipeId, setNewRecipeId] = useState<string | undefined>(undefined);
  const [recipeName, setRecipeName] = useState({
    en: initialName,
    de: initialNameDe,
  });
  const debouncedValue = useDebounceValue(recipeName, 500);

  const { data, isFetching, isLoading } = useQuery({
    queryKey: [debouncedValue],
    queryFn: () => recipeService.validateRecipeName([debouncedValue.en, debouncedValue.de]),
    enabled: !!debouncedValue,
  });

  const onConfirm = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    if (!recipe?.id) return;
    event?.stopPropagation();

    const { id } = await handleDuplicateRecipe({
      recipeId: recipe?.id,
      recipeName,
      onSuccess: () => setIsSuccess(true),
    });

    setNewRecipeId(id);
  };

  if (!recipe) return null;

  const renderBody = () => {
    if (isDuplicating)
      return <Typography>Duplicating recipe... This may take a few moments.</Typography>;
    if (!isDuplicating && isSuccess)
      return (
        <Typography>
          Recipe duplicated successfully and added to your recipe list. Please review and edit the
          new recipe as needed.
        </Typography>
      );

    return (
      <>
        <Typography variant='body1' color='kqn.darkerGray'>
          Duplicating this recipe will create a new recipe with identical data.
        </Typography>
        <Typography variant='body1' color='kqn.darkerGray'>
          Please provide a unique name in both language fields for the new recipe.
        </Typography>
      </>
    );
  };

  const renderFooter = () => {
    if (!newRecipeId || !isSuccess) return;

    return (
      <Button
        data-testid='confirm-button'
        variant='contained'
        onClick={(e) => {
          navigate(`/recipes/details?recipeId=${newRecipeId}`);
          onClose(e);
        }}
      >
        GO TO RECIPE
      </Button>
    );
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={!isSuccess ? onConfirm : undefined}
      disabled={!recipeName || !data || isFetching || isLoading}
      isProcessing={isDuplicating}
      title='DUPLICATE RECIPE'
      footerEl={renderFooter()}
    >
      <Stack pb={3} gap={2}>
        <Stack direction='column'>{renderBody()}</Stack>
        {!isDuplicating && !isSuccess && (
          <Stack gap={1}>
            <Title title='Recipe Name' />
            <KQNTextField
              size='small'
              fullWidth
              variant='outlined'
              value={recipeName.en}
              onChange={(e) => setRecipeName({ ...recipeName, en: e.target.value })}
              error={data === false}
              helperText={data === false ? 'Recipe name already exists.' : ''}
              InputProps={{ startAdornment: <TextInputAndormnet text='EN' position='start' /> }}
            />
            <KQNTextField
              size='small'
              fullWidth
              variant='outlined'
              value={recipeName.de}
              onChange={(e) => setRecipeName({ ...recipeName, de: e.target.value })}
              error={data === false}
              helperText={data === false ? 'Recipe name already exists.' : ''}
              InputProps={{ startAdornment: <TextInputAndormnet text='DE' position='start' /> }}
            />
          </Stack>
        )}
      </Stack>
    </ConfirmModal>
  );
};

export default DuplicateRecipeModal;
