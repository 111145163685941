import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { PaginatedResult } from '../../../types';
import { FiltersType, RecipeBaseModel, RecipeFilterQuery, RecipeMetadata } from '../../types';

const basePath = Paths.RECIPES;

const getRecipesByIds = async ({
  recipeIds,
  pageParams,
}: {
  recipeIds: string[];
  pageParams: number;
}) => {
  const recipes = recipeIds.map((id) => `ids=${id}`).join('&');

  return koqoonApi
    .get(`${basePath}/find/recipes?${recipes}`, {
      params: { page: pageParams },
    })
    .then((response) => {
      return response.data as PaginatedResult<RecipeBaseModel>;
    });
};

const getAllRecipes = async ({
  categoryId,
  filterQuery,
}: {
  categoryId?: string;
  filterQuery: RecipeFilterQuery;
}) => {
  try {
    if (!categoryId) {
      const response = await koqoonApi.get(`${basePath}`, {
        params: { ...filterQuery },
      });

      return response.data as PaginatedResult<RecipeBaseModel>;
    }

    const response = await koqoonApi.get(`${basePath}/category/${categoryId}`, {
      params: { ...filterQuery },
    });

    return response.data as PaginatedResult<RecipeBaseModel>;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getRecipes = async (filterQuery: RecipeFilterQuery) => {
  try {
    const response = await koqoonApi.get(`${basePath}/filter`, { params: { ...filterQuery } });

    return response.data as PaginatedResult<RecipeBaseModel>;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getRecipeById = async (recipeId?: string) => {
  if (!recipeId) return;

  try {
    const response = await koqoonApi.get(`${basePath}/${recipeId}`);

    return response.data as RecipeBaseModel;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getRecipeDetails = async (id?: string) => {
  try {
    const response = await koqoonApi.get(`${basePath}/${id}/details`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getAvailableFilters = async () => {
  try {
    const response = await koqoonApi.get(`${basePath}/filters`);

    return response.data as FiltersType;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const createRecipe = async (recipe: FormData) => {
  try {
    const response = await koqoonApi.post(`${basePath}`, recipe, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const createRecipeGeneralPhase = async (data: RecipeMetadata) => {
  try {
    const response = await koqoonApi.post(`${basePath}/import`, data);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const publishRecipe = async (recipeId: string) => {
  try {
    const response = await koqoonApi.put(`${basePath}/${recipeId}/publish`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getRecipeMealTypes = async (recipeId?: string) => {
  if (!recipeId) return [];

  try {
    const response = await koqoonApi.get(`${basePath}/${recipeId}/meal-types`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getRecipeInfo = async (recipeId?: string) => {
  if (!recipeId) {
    return { groups: 0, steps: 0, mealTypeSteps: 0 };
  }

  try {
    const response = await koqoonApi.get(`${basePath}/${recipeId}/info`);

    return response.data as { groups: number; steps: number; mealTypeSteps: number };
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const deleteRecipe = async (recipeId?: string) => {
  if (!recipeId) return;
  try {
    const response = await koqoonApi.delete(`${basePath}/${recipeId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const enableRecipe = async (recipeId: string) => {
  if (!recipeId) return;
  try {
    const response = await koqoonApi.post(`${basePath}/${recipeId}/enable`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const duplicateRecipe = async ({
  recipeName,
  recipeId,
}: {
  recipeId?: string;
  recipeName: { en: string; de: string };
}) => {
  if (!recipeId) return;
  try {
    const response = await koqoonApi.post(`${basePath}/${recipeId}/duplicate`, {
      recipeName,
    });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const validateRecipeName = async (names: string[]) => {
  try {
    const urlNameQuery = encodeURIComponent(names[0]);
    const urlNameDeQuery = encodeURIComponent(names[1]);
    const response = await koqoonApi.get(`${basePath}/validate-name`, {
      params: { name: urlNameQuery, nameDe: urlNameDeQuery },
    });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removeImage = async (recipeId: string) => {
  try {
    const response = await koqoonApi.delete(`${basePath}/${recipeId}/remove-image`);

    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const recipeService = {
  getRecipes,
  removeImage,
  createRecipe,
  deleteRecipe,
  enableRecipe,
  publishRecipe,
  getRecipeInfo,
  getRecipeById,
  duplicateRecipe,
  getRecipesByIds,
  getRecipeDetails,
  getRecipeMealTypes,
  validateRecipeName,
  getAvailableFilters,
  getAllRecipes,
  createRecipeGeneralPhase,
};
