import { koqoonApi } from '../../../../../../../../../config';
import { Paths } from '../../../../../../../../../constants';
import { Duration, Notification, Ordinal } from '../../../../../../../../types';

const basePath = Paths.NOTIFICATIONS;

const upsertNotification = async (stepId: string, notification: Notification) => {
  if (!stepId) return;
  try {
    const response = await koqoonApi.post(basePath, { notification, stepId });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removeNotification = async (stepId: string, notificationId: string) => {
  if (!notificationId || !stepId) return;

  try {
    const response = await koqoonApi.delete(`${basePath}/${notificationId}?stepId=${stepId}`);

    return response.data as Duration[];
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const updateNotificationsOrdinals = async (args: Array<Ordinal>) => {
  try {
    const response = await koqoonApi.put(`${basePath}/ordinals`, args);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const duplicateNotification = async (notificationId: string, stepId: string) => {
  try {
    const response = await koqoonApi.post(`${basePath}/duplicate`, { notificationId, stepId });

    return response.data as { notification: Notification; durations: Duration[] };
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const notificationService = {
  updateNotification: upsertNotification,
  updateNotificationsOrdinals,
  duplicateNotification,
  removeNotification,
};
