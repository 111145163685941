import { koqoonApi } from '../../../../../../../config';
import { Paths } from '../../../../../../../constants';
import {
  CreateRecipePreparationRequest,
  Ordinal,
  RecipePreparation,
} from '../../../../../../types';

const basePath = Paths.PREPARATIONS;

const getPreparationSteps = async (recipeId: string) => {
  try {
    const response = await koqoonApi.get(`${basePath}/recipes/${recipeId}`);

    return response.data as RecipePreparation[];
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const updatePreparationsOrdinals = async (args: Array<Ordinal>) => {
  try {
    const response = await koqoonApi.put(`${basePath}/ordinals`, args);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const addPreparationStep = async (step: CreateRecipePreparationRequest, recipeId?: string) => {
  try {
    const response = await koqoonApi.post(basePath, { recipeId, step });

    return response.data as RecipePreparation;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removePreparationStep = async (stepId?: string) => {
  if (!stepId) return;

  try {
    const response = await koqoonApi.delete(`${basePath}/${stepId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const preparationService = {
  addPreparationStep,
  getPreparationSteps,
  removePreparationStep,
  updatePreparationsOrdinals,
};
