import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../../../../../../../queries';
import { Notification, Ordinal } from '../../../../../../../../../../types';
import { notificationService } from '../../../service';

export const useUpdateNotificationsOrdinals = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { args: Array<Ordinal> }>(
    ({ args }) => notificationService.updateNotificationsOrdinals(args),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.meals.filter._def);
        toast.success('Order updated successfully');
      },
      onError: () => {
        toast.error('Something went wrong');
      },
    },
  );

  const handleUpdateNotificationsOrdinals = async (data: Array<Notification>) => {
    const updated = data.map((n, idx) => ({ id: n.id, ordinal: idx + 1 }));
    mutation.mutate(
      { args: updated },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          toast.success('Order updated successfully');
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      },
    );
  };

  return { handleUpdateNotificationsOrdinals, isLoading: mutation.isLoading, mutation };
};
