import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { queries } from '../../queries';
import { recipeService } from '../services/recipe-service';

interface Props {
  recipeId: string;
  recipeName: { en: string; de: string };
  onSuccess?: () => void;
}

export const useDuplicateRecipe = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    { id: string; name: string },
    unknown,
    { recipeId?: string; recipeName: { en: string; de: string } }
  >(({ recipeId, recipeName }) => recipeService.duplicateRecipe({ recipeId, recipeName }));

  const handleDuplicateRecipe = async ({ recipeId, recipeName, onSuccess }: Props) => {
    return mutation.mutateAsync(
      { recipeId, recipeName },
      {
        onSuccess: ({ id }) => {
          onSuccess?.();
          queryClient.refetchQueries(queries.recipes.filter._def);
          queryClient.refetchQueries(queries.recipes.infinityFilter._def);
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    handleDuplicateRecipe,
    isLoading: mutation.isLoading,
    mutation,
  };
};
