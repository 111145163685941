import { rectIntersection } from '@dnd-kit/core';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DragNDropContext, Optional } from '../../../../../../../../../../../../components';
import { useHandleDragEnd } from '../../../../../../../../../../../../shared';
import { useMealActions, useMealState } from '../../../../../../../../../../../../store/meal-store';
import { Meal } from '../../../../../../../../../../../types';
import presets from '../../../../../preset/components/preset/presets';
import { MealSteps } from '../../../meal-steps';
import MealComposer from '../../meal-composer';
import { useUpdateMealsOrdinals } from '../../mutations';
import { MealCard, NewMealCard } from '../meal-card';

type Props = {
  recipeId: string;
  isLoading?: boolean;
};

const MealCards: React.FC<Props> = ({ recipeId, isLoading }) => {
  const meals = useMealState();
  const { setData } = useMealActions();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const meal = useMemo(() => meals.find((m) => m.id === selected), [meals, selected]);
  const { handleUpdateMealsOrdinals } = useUpdateMealsOrdinals();

  const handleStepClick = (stepId: string) => {
    setSelected((prev) => (prev === stepId ? null : stepId));
  };

  const handleOnUpdate = (updated: Meal[]) => {
    setData(updated);
    handleUpdateMealsOrdinals(updated);
  };

  const handleDragEnd = useHandleDragEnd<Meal>({
    data: meals || [],
    onUpdate: handleOnUpdate,
  });

  return (
    <Stack direction='column'>
      <Grid container spacing={2}>
        <DragNDropContext
          items={meals}
          onDragEnd={handleDragEnd}
          collisionDetection={rectIntersection}
          strategy={horizontalListSortingStrategy}
        >
          {meals?.map((m, idx) => {
            return (
              <Grid item key={m.id}>
                <MealCard
                  isActive={selected === m.id}
                  recipeId={recipeId}
                  meal={m}
                  ordinal={idx}
                  onClick={handleStepClick}
                />
              </Grid>
            );
          })}
        </DragNDropContext>
        <Grid item>
          <NewMealCard onClick={() => setIsOpen(true)} />
        </Grid>
      </Grid>
      <Optional condition={!presets.length}>
        <Typography variant='h6' align='center'>
          Please select a meal type step to add grill steps to it. You can add multiple grill steps
          to a meal type step.
        </Typography>
      </Optional>
      <Optional condition={meal}>
        <MealSteps meal={meal!} />
      </Optional>
      <MealComposer
        recipeId={recipeId}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};
export default MealCards;
