import { Box, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  AssociatedRecipes,
  AssociatedRecipesHandler,
  DetailsTableSkeleton,
  MainLayout,
  Navigation,
  TableWrapper,
} from '../../../components';
import { queries } from '../../queries';
import { IngredientSummary } from '../components';

const IngredientDetails = () => {
  const { id } = useParams();

  const { data, isLoading, isFetching } = useQuery({
    ...queries.ingredients.details(id),
    enabled: !!id,
  });

  if (isLoading || isFetching) return <DetailsTableSkeleton title={data?.name} />;

  if (!data) return <div>Ingredient not found</div>;

  return (
    <TableWrapper>
      <Box sx={{ width: '100%' }}>
        <Navigation />
        <MainLayout>
          <Stack direction='row' gap={4} justifyContent='flex-start'>
            <IngredientSummary ingredient={data} />
            <AssociatedRecipesHandler recipeIds={data.groups}>
              {({ data: recipesData, ref }) => (
                <AssociatedRecipes ref={ref} recipes={recipesData} />
              )}
            </AssociatedRecipesHandler>
          </Stack>
        </MainLayout>
      </Box>
    </TableWrapper>
  );
};

export default IngredientDetails;
