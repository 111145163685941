import AddIcon from '@mui/icons-material/Add';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ModalComposer } from '../../../../components';
import { capitalizeLetter } from '../../../../shared';
import { queries } from '../../../queries';
import { CreateNutrientRequest, Nutrient } from '../../../types';
import { mapUnitsResponseToObject } from '../../../units';
import { useCreateIngredient } from '../../mutations';
import { IngredientUpdateSchemaType, UpsertIngredientForm } from '../forms';
import { useFormChangeActions } from './hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateIngredientModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const { handleCreateIngredient, isLoading } = useCreateIngredient();

  const { data: nutrients } = useQuery({
    ...queries.nutrients.all(),
    select: (data) => data.map((nutrient) => ({ ...nutrient, amount: 0 })),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data: units } = useQuery({
    ...queries.units.all(),
    select: (data) => mapUnitsResponseToObject(data).data,
    staleTime: 0,
    refetchOnMount: true,
  });

  const [nutrientValues, setNutrientValues] = useState<Nutrient[]>([]);

  useEffect(() => {
    if (nutrients) {
      setNutrientValues(nutrients);
    }
  }, [nutrients]);

  const { handleChange, handleNutrientChange } = useFormChangeActions({
    setNutrientValues,
    setSelectedUnits,
  });

  const handleOnSubmit = async (data: IngredientUpdateSchemaType) => {
    if (!units || !nutrients) return;

    const mappedNutrients = nutrients.map((nutrient) => {
      const selectedNutrient = nutrientValues.find((selected) => selected?.id === nutrient.id);
      return selectedNutrient;
    });

    const body = {
      name: capitalizeLetter(data.name),
      nameDe: capitalizeLetter(data.nameDe),
      units: units.filter((unit) => selectedUnits.includes(unit.name)).map((unit) => unit.id),
      nutrients: mappedNutrients.map((nutrient) => ({
        nutrientId: nutrient?.id,
        amount: nutrient?.amount,
      })) as CreateNutrientRequest[],
    };

    await handleCreateIngredient({ data: body, onSuccess: onClose });
  };

  return (
    <ModalComposer
      icon={<AddIcon className='icon' />}
      title='ADD INGREDIENT'
      isOpen={isOpen}
      onClose={onClose}
      sx={{ width: '800px' }}
    >
      <UpsertIngredientForm
        onClose={onClose}
        units={units || []}
        nutrients={nutrientValues}
        onSubmit={handleOnSubmit}
        onUnitChange={handleChange}
        selectedUnits={selectedUnits}
        onNutrientChange={handleNutrientChange}
        isSubmitting={isLoading}
      />
    </ModalComposer>
  );
};

export default CreateIngredientModal;
