import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../../../../../../../../../queries';
import { mealStepService } from '../../../../../../../../../../queries/services';
import { Meal, Ordinal } from '../../../../../../../../../../types';

export const useUpdateMealsOrdinals = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, unknown, { args: Array<Ordinal> }>(({ args }) =>
    mealStepService.updateMealsOrdinals(args),
  );

  const handleUpdateMealsOrdinals = async (meals: Meal[]) => {
    const updated = meals.map((m, idx) => ({ id: m.id, ordinal: idx + 1 }));
    mutation.mutate(
      { args: updated },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          toast.success('Order updated successfully');
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      },
    );
  };

  return { handleUpdateMealsOrdinals, mutation, isLoading: mutation.isLoading };
};
