import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { Meal, Ordinal } from '../../types';

const basePath = Paths.MEALS;

const getAllRecipeMealTypeSteps = async (recipeId: string | null): Promise<Meal[]> => {
  if (!recipeId) return [];

  try {
    const response = await koqoonApi.get(`${basePath}/recipes/${recipeId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const updateMealsOrdinals = async (args: Array<Ordinal>) => {
  try {
    const response = await koqoonApi.put(`${basePath}/ordinals`, args);
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const mealStepService = {
  updateMealsOrdinals,
  getAllRecipeMealTypeSteps,
};
